import {
  StateDefinitions,
  StateIndices,
} from '../storage/state-declaration.js';
import { object } from '../typing/object.js';
import { Type } from '../typing/type.js';
import { buildValidator } from '../typing/build-validator.js';

export interface PartitionKey {
  name: string;
  type: Type<string | number | boolean | Date, any>;
}

export function partitionState<TState extends StateDefinitions>(
  state: TState,
  partitionKeys: PartitionKey[],
): TState {
  return Object.entries(state).reduce<StateDefinitions>((map, [key, value]) => {
    map[key] = {
      type: object(value.type.description.name, {
        ...Object.entries(value.type.description.object).reduce<
          Record<string, Type<any, any>>
        >((obj, [key, prop]) => {
          obj[`partition_${key}`] = buildValidator(prop);
          return obj;
        }, {}),
        ...partitionKeys.reduce<Record<string, Type<any, any>>>((map, k) => {
          map[k.name] = k.type;
          return map;
        }, {}),
      }),
      key: [
        ...partitionKeys.map((k) => k.name),
        ...value.key.map((k) => `partition_${k}`),
      ],
      indices: Object.entries(value.indices as StateIndices<any>).reduce<
        StateIndices<any>
      >((indexMap, [indexKey, indexValue]) => {
        indexMap[indexKey] = {
          fields: [
            ...partitionKeys.map((k) => k.name),
            ...(indexValue.fields as string[]).map((k) => `partition_${k}`),
          ],
          unique: indexValue.unique,
        };
        return indexMap;
      }, {}),
    };
    return map;
  }, {}) as TState;
}
