import { ReducedInstanceState } from './instance-storage.js';
import { StoredEvent } from '../event/stored-event.js';
import { RuntimeEnv } from '../runtime-env.js';
import { InstanceIdentifier } from './instance-identifier.js';
import { StateDefinitions } from '../storage/state-declaration.js';
import { HostRuntimeExecution } from '../runtime/host-runtime.js';
import {
  createPartitionedTransactionClient,
  tenantPartitionKeys,
} from './create-instance-storage.js';
import { MultiStorage } from '../storage/multi-storage.js';
import { RuntimeResult } from '../runtime/runtime-result.js';

export async function applyRuntimeResultToInstance<
  TState extends StateDefinitions,
>(
  runtime: HostRuntimeExecution<any>,
  streamStorage: { storage: MultiStorage<ReducedInstanceState<TState>> },
  streamId: InstanceIdentifier,
  evt: StoredEvent,
  env: RuntimeEnv,
  seed: string,
): Promise<RuntimeResult> {
  return streamStorage.storage.transaction(async (trx) => {
    const random = await env.sha1(seed + streamId.name + evt.id);
    const stateTrx = createPartitionedTransactionClient(
      trx.storage('state'),
      tenantPartitionKeys.map((k) => k.name),
      {
        name: streamId.name,
        tenant: streamId.tenant,
        version: streamId.version,
        pattern: streamId.pattern,
        realm: streamId.realm,
      },
    );
    const result = await runtime.execute(
      stateTrx,
      evt,
      {
        text: random,
      },
      env,
    );

    await trx.storage('processor').state('operations').insert({
      realm: streamId.realm,
      pattern: streamId.pattern,
      version: streamId.version,
      name: streamId.name,
      tenant: streamId.tenant,
      eventId: evt.id,
      result,
      appliedAt: new Date(),
      eventAt: evt.createdAt,
    });

    if (result.success) {
      await trx.storage('processor').state('eventSequence').insert({
        realm: streamId.realm,
        pattern: streamId.pattern,
        version: streamId.version,
        name: streamId.name,
        tenant: streamId.tenant,
        eventId: evt.id,
        appliedAt: new Date(),
        error: undefined,
      });
    }

    return result;
  });
}
