import {
  StateDeclarations,
  StateDefinitions,
} from '../storage/state-declaration.js';
import { EventDeclarations } from '../event/event-declarations.js';
import { OutputDeclarations } from './output-declarations.js';
import { serializeObject } from '../utils/serialize-object.js';
import { VersionBuilder } from '../runtime/create-aggregate-host.js';
import { RuntimeEnv } from '../runtime-env.js';

export function generateStateVersion(state: StateDefinitions, env: RuntimeEnv) {
  const versionString = serializeObject(
    Object.entries(state).map(([name, value]) => {
      return {
        name,
        type: value.type.description,
      };
    }),
  );

  return env.sha1(versionString);
}

export function generateVersion<
  TState extends StateDeclarations,
  TEvent extends EventDeclarations,
  TOutput extends OutputDeclarations,
>(state: TState, event: TEvent, output: TOutput): VersionBuilder {
  const versionString = serializeObject({
    state: Object.entries(state).map(([name, value]) => {
      return {
        name,
        type: value.type.description,
      };
    }),
    event: Object.entries(event).map(([name, value]) => {
      return {
        name,
        type: value.description,
      };
    }),
    output: Object.entries(output).map(([name, value]) => {
      return {
        name,
        type: value.description,
      };
    }),
  });
  let promise: Promise<string> | undefined = undefined;

  return {
    source: versionString,
    resolve(env: RuntimeEnv): Promise<string> {
      if (!promise) {
        promise = env.sha1(versionString);
      }
      return promise;
    },
  };
}
