import { TypeDescription } from './type-description.js';
import { union } from './union.js';
import { record } from './record.js';
import { optional } from './optional.js';
import { nullable } from './nullable.js';
import { numberAsText } from './number-as-text.js';
import { date } from './date.js';
import { literal } from './literal.js';
import { array } from './array.js';
import { string } from './string.js';
import { number } from './number.js';
import { boolean } from './boolean.js';
import { object } from './object.js';
import { Type } from './type.js';
import { failNever } from '../utils/fail-never.js';
import { func } from './func.js';
import { promise } from './promise.js';
import { getOrCreateRecursive } from '../utils/get-or-create.js';

function buildInternalValidator(
  type: TypeDescription,
  map: Record<string, Type<any, any>>,
): Type<any, any> {
  if (type.type === 'boolean') {
    return boolean();
  } else if (type.type === 'string') {
    return string();
  } else if (type.type === 'numberAsText') {
    return numberAsText();
  } else if (type.type === 'number') {
    return number();
  } else if (type.type === 'date') {
    return date();
  } else if (type.type === 'array') {
    return array(buildInternalValidator(type.itemType, map));
  } else if (type.type === 'literal') {
    return literal(type.constant);
  } else if (type.type === 'optional') {
    return optional(buildInternalValidator(type.optionalType, map));
  } else if (type.type === 'object') {
    return getOrCreateRecursive(
      map,
      type.name,
      () =>
        object(
          type.name,
          Object.entries(type.object).reduce((map, [key, prop]) => {
            map[key] = buildInternalValidator(prop as any, map);
            return map;
          }, {} as any) as any,
        ),
      ['description', 'safeParse'],
    );
  } else if (type.type === 'union') {
    return getOrCreateRecursive(
      map,
      type.name,
      () =>
        union(
          type.name,
          type.unionTypes.map((u) => buildInternalValidator(u, map)) as any,
        ),
      ['description', 'safeParse'],
    );
  } else if (type.type === 'record') {
    return record(
      buildInternalValidator(type.keyType, map),
      buildInternalValidator(type.valueType, map),
    );
  } else if (type.type === 'nullable') {
    return nullable(buildInternalValidator(type.nullableType, map));
  } else if (type.type === 'func') {
    return func(
      type.argumentTypes.map((a) => buildInternalValidator(a, map)),
      buildInternalValidator(type.returnType, map),
    );
  } else if (type.type === 'promise') {
    return promise(buildInternalValidator(type.returnType, map));
  } else {
    failNever(type, 'unknown type');
  }
}

export function buildValidator(type: TypeDescription): Type<any, any> {
  return buildInternalValidator(type, {});
}
