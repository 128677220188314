import {
  StateDefinition,
  StateDefinitions,
  StateIndices,
} from '../state-declaration.js';
import { TypeDescription } from '../../typing/type-description.js';

export function isCompatibleStateDefinitions(
  existing: StateDefinitions,
  expected: StateDefinitions,
): boolean {
  for (const [name, expectedState] of Object.entries(expected)) {
    const existingState = existing[name];
    if (!existingState) {
      continue;
    }

    if (!isCompatibleStateDefinition(existingState, expectedState)) {
      return false;
    }
  }

  return true;
}

export function isCompatibleStateDefinition(
  existing: StateDefinition<any, string, StateIndices<string[]>>,
  expected: StateDefinition<any, string, StateIndices<string[]>>,
): boolean {
  if (!equalArray(existing.key, expected.key)) {
    return false;
  }

  if (
    !isCompatibleTypeDescription(
      existing.type.description,
      expected.type.description,
    )
  ) {
    return false;
  }

  for (const [name, expectedIndex] of Object.entries(expected.indices)) {
    const existingIndex = existing.indices[name];
    if (!existingIndex) {
      return false;
    }

    if (!equalArray(existingIndex.fields, expectedIndex.fields)) {
      return false;
    }

    if (expectedIndex.unique !== existingIndex.unique) {
      return false;
    }
  }

  return true;
}

export function isCompatibleTypeDescription(
  existing: TypeDescription,
  expected: TypeDescription,
): boolean {
  if (existing.type === 'string' && expected.type === 'string') {
    return true;
  }

  if (existing.type === 'boolean' && expected.type === 'boolean') {
    return true;
  }

  if (existing.type === 'number' && expected.type === 'number') {
    return true;
  }

  if (existing.type === 'numberAsText' && expected.type === 'numberAsText') {
    return true;
  }

  if (existing.type === 'date' && expected.type === 'date') {
    return true;
  }

  if (existing.type === 'nullable' && expected.type === 'nullable') {
    return isCompatibleTypeDescription(
      existing.nullableType,
      expected.nullableType,
    );
  }

  if (existing.type === 'optional' && expected.type === 'optional') {
    return isCompatibleTypeDescription(
      existing.optionalType,
      expected.optionalType,
    );
  }

  if (existing.type === 'array' && expected.type === 'array') {
    return isCompatibleTypeDescription(existing.itemType, expected.itemType);
  }

  if (existing.type === 'record' && expected.type === 'record') {
    return (
      isCompatibleTypeDescription(existing.keyType, expected.keyType) &&
      isCompatibleTypeDescription(existing.valueType, expected.valueType)
    );
  }

  if (existing.type === 'literal' && expected.type === 'literal') {
    return existing.constant === expected.constant;
  }

  if (existing.type === 'union' && expected.type === 'union') {
    for (const existingUnionType of existing.unionTypes) {
      if (
        !expected.unionTypes.some((expectedUnionType) =>
          isCompatibleTypeDescription(existingUnionType, expectedUnionType),
        )
      ) {
        return false;
      }
    }
    return true;
  }

  if (existing.type === 'object' && expected.type === 'object') {
    for (const [propName, existingProp] of Object.entries(existing.object)) {
      const expectedProp = expected.object[propName];
      if (!expectedProp) {
        continue;
      }

      if (!isCompatibleTypeDescription(existingProp, expectedProp)) {
        return false;
      }
    }

    for (const [propName, expectedProp] of Object.entries(expected.object)) {
      const existingProp = existing.object[propName];
      if (existingProp) {
        if (!isCompatibleTypeDescription(existingProp, expectedProp)) {
          return false;
        }
      } else if (isRequiredType(expectedProp)) {
        return false;
      }
    }
    return true;
  }

  if (existing.type === 'promise' && expected.type === 'promise') {
    return isCompatibleTypeDescription(
      existing.returnType,
      expected.returnType,
    );
  }

  if (existing.type === 'func' && expected.type === 'func') {
    if (
      !isCompatibleTypeDescription(existing.returnType, expected.returnType)
    ) {
      return false;
    }

    for (
      let i = 0;
      i <
      Math.max(existing.argumentTypes.length, expected.argumentTypes.length);
      i++
    ) {
      const existingArg = existing.argumentTypes[i];
      const expectedArg = expected.argumentTypes[i];

      if (expectedArg && !existingArg) {
        return false;
      }

      if (!expectedArg && existingArg) {
        if (existingArg.type !== 'optional') {
          return false;
        }
      }

      if (expectedArg && existingArg) {
        if (!isCompatibleTypeDescription(expectedArg, existingArg)) {
          return false;
        }
      }
    }
  }

  if (expected.type === 'nullable') {
    return isCompatibleTypeDescription(existing, expected.nullableType);
  }

  if (expected.type === 'optional') {
    return isCompatibleTypeDescription(existing, expected.optionalType);
  }

  return false;
}

export function isRequiredType(type: TypeDescription): boolean {
  if (type.type === 'nullable' || type.type === 'optional') {
    return false;
  }

  if (
    type.type === 'union' &&
    type.unionTypes.some((t) => t.type === 'nullable' || t.type === 'optional')
  ) {
    return false;
  }

  return true;
}

export function equalArray(first: string[], second: string[]): boolean {
  if (first.length !== second.length) {
    return false;
  }

  for (let i = 0; i < first.length; i++) {
    if (first[i] !== second[i]) {
      return false;
    }
  }

  return true;
}
