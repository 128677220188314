import { StorageInterface, TransactionClient } from '../storage-interface.js';
import {
  StateDefinition,
  StateDefinitions,
  StateKeyValues,
  TypeOfStateDeclaration,
} from '../state-declaration.js';
import { StateUpdate } from './state-update.js';
import { SnapshotClient } from '../snapshot-client.js';
import { PatchType, StorageClient, UpsertType } from '../storage-client.js';
import {
  PublishMessageOptions,
  Queue,
  TopicInstance,
} from '../../queue/queue.js';
import { stateUpdateTopic } from '../../management/aggregation-attach-topic.js';
import { createLazy } from '../../management/create-lazy.js';
import { Lazy } from '../../management/lazy.js';
import { TopicDefinition } from '../../queue/define-topic.js';
import { getStateDataKeys } from '../../runtime/get-state-data-keys.js';
import { getOrFail } from '../../realm/get-or-fail.js';
import { TrackedStorage } from './tracked-state.js';
import { observedClient, ObservedClient } from '../observer/observed-client.js';
import { createStateObserver } from '../observer/create-observer.js';

async function delayedTopic<Result, Topic extends TopicDefinition<any, any>>(
  lazyTopic: Lazy<TopicInstance<Topic>>,
  fn: (delayedTopic: Lazy<TopicInstance<Topic>>) => Promise<Result>,
): Promise<Result> {
  const publishes: {
    message: Topic['data']['_output'];
    options: PublishMessageOptions<Topic['attributes']>;
  }[] = [];

  const delayedLazy = createLazy<TopicInstance<typeof stateUpdateTopic>>(
    async () => {
      return {
        publish: async (message, options) => {
          publishes.push({ message, options });
        },
      };
    },
  );
  const topic = await lazyTopic.resolve();
  const result = await fn(delayedLazy);
  for (const publish of publishes) {
    await topic.publish(publish.message, publish.options);
  }
  return result;
}

export function createTrackedStorage<TState extends StateDefinitions>(
  storageName: string,
  state: TState,
  source: StorageInterface<TState>,
  queue: Queue,
  signal: AbortSignal,
): TrackedStorage<TState> {
  const lazyTopic = createLazy(() => queue.createTopic(stateUpdateTopic));
  const observer = createStateObserver(storageName, state, queue, signal);

  return {
    observe<K extends string & keyof TState>(
      name: K,
    ): ObservedClient<TState[K]> {
      return observedClient(source, observer.watch(name), state, name);
    },
    snapshot<K extends string & keyof TState>(
      name: K,
    ): SnapshotClient<TState[K]> {
      return source.snapshot(name);
    },
    async transaction<T>(
      fn: (client: TransactionClient<TState>) => Promise<T>,
    ): Promise<T> {
      return delayedTopic(lazyTopic, (delayedTopic) => {
        return source.transaction((trx) =>
          fn({
            state<K extends string & keyof TState>(
              name: K,
            ): StorageClient<TState[K]> {
              return createTrackedState(
                storageName,
                name,
                getOrFail(state, name),
                trx.state(name),
                delayedTopic,
              );
            },
            snapshot<K extends string & keyof TState>(
              name: K,
            ): SnapshotClient<TState[K]> {
              return source.snapshot(name);
            },
          }),
        );
      });
    },
    state<K extends string & keyof TState>(name: K): StorageClient<TState[K]> {
      return createTrackedState(
        storageName,
        name,
        getOrFail(state, name),
        source.state(name),
        lazyTopic,
      );
    },
  };
}

async function emitCallbacks(
  lazyTopic: Lazy<TopicInstance<typeof stateUpdateTopic>>,
  storageName: string,
  update: StateUpdate,
) {
  const topic = await lazyTopic.resolve();
  await topic.publish(
    {
      update,
    },
    {
      attributes: {
        state: update.state,
        storageName,
      },
    },
  );
}

function createTrackedState<TState extends StateDefinition<any, any, any>>(
  storageName: string,
  state: string,
  declaration: TState,
  source: StorageClient<TState>,
  delayedTopic: Lazy<TopicInstance<typeof stateUpdateTopic>>,
): StorageClient<TState> {
  return {
    async upsert(val: UpsertType<TState>): Promise<void> {
      await source.upsert(val);
      const { data, keys } = getStateDataKeys(declaration, val);
      await emitCallbacks(delayedTopic, storageName, {
        type: 'upsert',
        state,
        data,
        keys,
      });
    },
    async insert(val: TypeOfStateDeclaration<TState>): Promise<void> {
      await source.insert(val);
      const { data, keys } = getStateDataKeys(declaration, val);
      await emitCallbacks(delayedTopic, storageName, {
        type: 'insert',
        state,
        data,
        keys,
      });
    },
    async delete(id: StateKeyValues<TState>): Promise<void> {
      await source.delete(id);
      const { keys } = getStateDataKeys(declaration, id);
      await emitCallbacks(delayedTopic, storageName, {
        type: 'delete',
        state,
        keys,
      });
    },
    // async clear(): Promise<void> {
    //   await source.clear();
    //   await emitCallbacks(delayedTopic, storageName, {
    //     type: 'clear',
    //     state,
    //   });
    // },
    async patch(val: PatchType<TState>): Promise<void> {
      await source.patch(val);
      const { data, keys } = getStateDataKeys(declaration, val);
      await emitCallbacks(delayedTopic, storageName, {
        type: 'patch',
        state,
        data,
        keys,
      });
    },
  };
}
