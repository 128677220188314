import { Type } from './type.js';
import { createType } from './create-type.js';
import { ValidateError } from './validate-error.js';
import { UnionTypeDescription } from './union-type-description.js';

//export const union = <T extends [Type<any, any>, ...Type<any, any>[]]>(
export const union = <T extends Type<any, any>[]>(name: string, kinds: T) =>
  createType<T[number]['_output'], UnionTypeDescription>({
    description: {
      type: 'union',
      unionTypes: kinds.map((k) => k.description),
      name,
    },
    validate: (val, path) => {
      for (const kind of kinds) {
        const res = kind.safeParse(val, path);
        if (res.success) {
          return { success: true, data: res.data };
        }
      }
      return {
        success: false,
        error: new ValidateError('not union type', path, val),
      };
    },
  });
