import { EventDeclarations } from './event-declarations.js';
import { StoredEvent } from './stored-event.js';
import { EventRecord } from './event-record.js';
import {
  EventPushOptions,
  EventStoreOptions,
  ImmutableEventStore,
} from './event-store.js';
import { validate } from '../typing/validate.js';
import { CustomError } from '../custom-error.js';
import type { EventState } from './event-state.js';
import { TrackedStorage } from '../storage/tracker/tracked-state.js';
import { EventAuth, fallbackAuth } from './event-auth.js';
import { getAuthId } from '../management/get-auth-id.js';
import { RangeOptions } from '../storage/snapshot-index-client.js';
import { DefinitionIdentifier } from '../realm/definition-identifier.js';
import { firstOfRange } from '../storage/first-of-range.js';

export function createEventStore<TEvent extends EventDeclarations>(
  storage: TrackedStorage<typeof EventState>,
  storeOptions: EventStoreOptions<TEvent>,
): ImmutableEventStore<TEvent> {
  return {
    async get(
      identifier: DefinitionIdentifier,
      id: string,
    ): Promise<StoredEvent | null> {
      const evt = await firstOfRange(
        storage
          .snapshot('events')
          .index('id')
          .filter('tenant', identifier.tenant)
          .filter('realm', identifier.realm)
          .filter('pattern', identifier.pattern)
          .filter('id', id)
          .range(null, {}),
      );
      if (!evt) {
        return null;
      }
      return mapEvent(evt.data);
    },
    async *range(
      identifier: DefinitionIdentifier,
      auth: EventAuth,
      bookmark: Date | null,
      opts?: Partial<RangeOptions>,
    ): AsyncGenerator<StoredEvent> {
      const authId = await getAuthId(auth, storeOptions.env);
      const iterator = storage
        .snapshot('events')
        .index('authId')
        .filter('tenant', identifier.tenant)
        .filter('realm', identifier.realm)
        .filter('pattern', identifier.pattern)
        .filter('authId', authId)
        .range(bookmark, {
          dir: opts?.dir ?? 'next',
          open: opts?.open ?? true,
        });
      for await (const evt of iterator) {
        yield mapEvent(evt.data);
      }
    },
    async push<K extends string & keyof TEvent>(
      identifier: DefinitionIdentifier,
      evt: K,
      data: TEvent[K],
      options: EventPushOptions,
    ): Promise<StoredEvent> {
      const eventStruct = storeOptions.events[evt];
      if (!eventStruct) {
        throw new Error(`event type ${evt} not found`);
      }

      const [eventError, eventData] = validate(data, eventStruct);
      if (eventError) {
        throw new Error('event validation error: ' + eventError.message);
      }

      const auth = fallbackAuth(options.auth);
      const event: EventRecord = {
        id: options.id,
        createdAt: options.createdAt,
        event: evt,
        auth,
        authId: await getAuthId(auth, storeOptions.env),
        version: identifier.version,
        pattern: identifier.pattern,
        realm: identifier.realm,
        tenant: identifier.tenant,
        annotations: options.annotations,
        storedAt: new Date(),
        data: eventData,
      };
      try {
        await storage.state('events').insert(event);
      } catch (e) {
        throw new CustomError(`unable to insert event ${options.id}`, e, {
          id: options.id,
          event: evt,
        });
      }

      return mapEvent(event);
    },
  };
}

function mapEvent(evt: EventRecord): StoredEvent {
  return {
    id: evt.id,
    annotations: evt.annotations,
    data: evt.data,
    event: evt.event,
    createdAt: evt.createdAt,
    storedAt: evt.storedAt,
    auth: evt.auth,
    authId: evt.authId,
    realm: evt.realm,
    version: evt.version,
    pattern: evt.pattern,
    tenant: evt.tenant,
  };
}
