import { StorageBuilder } from '../storage/tracker/storage-builder.js';
import { EventState } from '../event/event-state.js';
import { AuthManagementState } from './auth/auth-management-state.js';
import { createMultiStorage } from '../storage/create-multi-storage.js';
import { MultiStorage } from '../storage/multi-storage.js';
import { TenantState } from './tenant-storage-factory.js';

export function createTenantStorage(
  storageBuilder: StorageBuilder,
  signal: AbortSignal,
): MultiStorage<TenantState> {
  return createMultiStorage(
    storageBuilder,
    'tenant',
    {
      event: EventState,
      auth: AuthManagementState,
    },
    signal,
  );
}
