import { Type } from './type.js';
import { createType } from './create-type.js';
import { ArrayTypeDescription } from './array-type-description.js';
import { ValidateError } from './validate-error.js';

function isLength(value: any): value is number {
  return typeof value === 'number' && value > -1 && value % 1 === 0;
}

export const isArray = (val: unknown): val is unknown[] =>
  typeof val === 'object' &&
  val !== null &&
  val.constructor.name === 'Array' &&
  'length' in val &&
  isLength(val.length);

export const array = <T extends Type<any, any>>(itemType: T) =>
  createType<T['_output'][], ArrayTypeDescription>({
    description: { type: 'array', itemType: itemType.description },
    validate: (val, path) => {
      if (isArray(val)) {
        const data: any[] = [];
        let index = 0;
        for (const item of val as unknown[]) {
          const result = itemType.safeParse(item, [...path, index.toString()]);
          if (result.success) {
            data.push(result.data);
          } else {
            return result;
          }
          index++;
        }
        return {
          success: true,
          data: data,
        };
      } else {
        return {
          success: false,
          error: new ValidateError('not an array', path, val),
        };
      }
    },
  });
