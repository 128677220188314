import { Lazy } from '../../management/lazy.js';
import { TrackedStorage } from './tracked-state.js';
import {
  StateDefinition,
  StateDefinitions,
  StateIndex,
  StateKeyValues,
  TypeOfStateDeclaration,
} from '../state-declaration.js';
import {
  DeleteType,
  PatchType,
  StorageClient,
  UpsertType,
} from '../storage-client.js';
import { SnapshotClient } from '../snapshot-client.js';
import { ObservedClient } from '../observer/observed-client.js';
import { TransactionClient } from '../storage-interface.js';
import { lazyWrap } from '../../management/lazy-wrap.js';
import { ObserveSingleOptions } from '../observer/observe-single-options.js';
import { ObservedIndexClient } from '../observer/observed-index-client.js';
import { ObserveRangeOptions } from '../observer/observe-range-options.js';
import { BrowseResult, RangeResult } from '../browse-result.js';
import { IterateOptions } from '../observer/iterate-options.js';
import {
  FilterKey,
  FilterStateIndex,
  FilterValue,
  RangeOptions,
  SnapshotIndexClient,
} from '../snapshot-index-client.js';
import { getOrFail } from '../../realm/get-or-fail.js';

export function createLazyTrackedStorage<TState extends StateDefinitions>(
  state: TState,
  lazy: Lazy<TrackedStorage<TState>>,
): TrackedStorage<TState> {
  return {
    state<K extends string & keyof TState>(name: K): StorageClient<TState[K]> {
      return createLazyStorageClient(
        lazyWrap(lazy, (storage) => storage.state(name)),
      );
    },
    snapshot<K extends string & keyof TState>(
      name: K,
    ): SnapshotClient<TState[K]> {
      return createLazySnapshotClient<TState[K]>(
        getOrFail(state, name) as TState[K],
        lazyWrap(lazy, (storage) => storage.snapshot(name)),
      );
    },
    observe<K extends string & keyof TState>(
      name: K,
    ): ObservedClient<TState[K]> {
      return createLazyObservedClient<TState[K]>(
        getOrFail(state, name) as TState[K],
        lazyWrap(lazy, (storage) => storage.observe(name)),
      );
    },
    async transaction<T>(
      fn: (client: TransactionClient<TState>) => Promise<T>,
    ): Promise<T> {
      const storage = await lazy.resolve();
      return storage.transaction<T>(fn);
    },
  };
}

function createLazyStorageClient<TState extends StateDefinition<any, any, any>>(
  lazy: Lazy<StorageClient<TState>>,
): StorageClient<TState> {
  return {
    async insert(val: TypeOfStateDeclaration<TState>): Promise<void> {
      const storage = await lazy.resolve();
      return storage.insert(val);
    },
    async patch(val: PatchType<TState>): Promise<void> {
      const storage = await lazy.resolve();
      return storage.patch(val);
    },
    async delete(id: DeleteType<TState>): Promise<void> {
      const storage = await lazy.resolve();
      return storage.delete(id);
    },
    async upsert(val: UpsertType<TState>): Promise<void> {
      const storage = await lazy.resolve();
      return storage.insert(val);
    },
    // async clear(): Promise<void> {
    //   const storage = await lazy.resolve();
    //   return storage.clear();
    // },
  };
}

function createLazyObservedIndexClient<
  TState extends StateDefinition<any, any, any>,
  TIndex extends StateIndex<any, any>,
>(
  index: StateIndex<any, any>,
  keyIndex: number,
  lazy: Lazy<ObservedIndexClient<TState, TIndex>>,
): ObservedIndexClient<TState, TIndex> {
  return {
    async *iterate(
      bookmark: FilterValue<TState, TIndex> | null,
      opts: IterateOptions,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState>>> {
      const client = await lazy.resolve();
      yield* client.iterate(bookmark, opts);
    },
    async *observeRange(
      bookmark: FilterValue<TState, TIndex> | null,
      opts: ObserveRangeOptions,
    ): AsyncGenerator<BrowseResult<TypeOfStateDeclaration<TState>>> {
      const client = await lazy.resolve();
      yield* client.observeRange(bookmark, opts);
    },
    filter(
      key: FilterKey<TIndex>,
      value: FilterValue<TState, TIndex>,
    ): ObservedIndexClient<TState, FilterStateIndex<TIndex>> {
      return createLazyObservedIndexClient(
        index,
        keyIndex + 1,
        lazyWrap(lazy, (client) => client.filter(key, value)),
      );
    },
  };
}

function createLazyObservedClient<
  TState extends StateDefinition<any, any, any>,
>(state: TState, lazy: Lazy<ObservedClient<TState>>): ObservedClient<TState> {
  return {
    async *observe(
      id: StateKeyValues<TState>,
      opts: ObserveSingleOptions,
    ): AsyncGenerator<TypeOfStateDeclaration<TState> | null> {
      const storage = await lazy.resolve();
      yield* storage.observe(id, opts);
    },
    index<K extends keyof TState['indices'] & string>(
      index: K,
    ): ObservedIndexClient<TState, TState['indices'][K]> {
      return createLazyObservedIndexClient<TState, TState['indices'][K]>(
        getOrFail(state.indices, index),
        0,
        lazyWrap(lazy, (storage) => storage.index(index)),
      );
    },
    async *observeRange(
      bookmark: StateKeyValues<TState> | null,
      opts: ObserveRangeOptions,
    ): AsyncGenerator<BrowseResult<TypeOfStateDeclaration<TState>>> {
      const storage = await lazy.resolve();
      yield* storage.observeRange(bookmark, opts);
    },
    async *iterate(
      bookmark: StateKeyValues<TState> | null,
      opts: IterateOptions,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState>>> {
      const storage = await lazy.resolve();
      yield* storage.iterate(bookmark, opts);
    },
  };
}

function createLazySnapshotIndexClient<
  TState extends StateDefinition<any, any, any>,
  TIndex extends StateIndex<any, any>,
>(
  index: StateIndex<any, any>,
  keyIndex: number,
  lazy: Lazy<SnapshotIndexClient<TState, TIndex>>,
): SnapshotIndexClient<TState, TIndex> {
  return {
    filter(
      key: FilterKey<TIndex>,
      value: FilterValue<TState, TIndex>,
    ): SnapshotIndexClient<TState, FilterStateIndex<TIndex>> {
      return createLazySnapshotIndexClient(
        index,
        keyIndex + 1,
        lazyWrap(lazy, (client) => client.filter(key, value)),
      );
    },
    async *range(
      bookmark: FilterValue<TState, TIndex> | null,
      opts?: Partial<RangeOptions>,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState>>> {
      const client = await lazy.resolve();
      yield* client.range(bookmark, opts);
    },
  };
}

function createLazySnapshotClient<
  TState extends StateDefinition<any, any, any>,
>(state: TState, lazy: Lazy<SnapshotClient<TState>>): SnapshotClient<TState> {
  return {
    index<K extends keyof TState['indices'] & string>(
      index: K,
    ): SnapshotIndexClient<TState, TState['indices'][K]> {
      return createLazySnapshotIndexClient(
        getOrFail(state.indices, index),
        0,
        lazyWrap(lazy, (client) => client.index(index)),
      );
    },
    async get(
      id: StateKeyValues<TState>,
    ): Promise<TypeOfStateDeclaration<TState> | null> {
      const client = await lazy.resolve();
      return client.get(id);
    },
    async *range(
      bookmark: StateKeyValues<TState> | null,
      opts?: Partial<RangeOptions>,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState>>> {
      const client = await lazy.resolve();
      yield* client.range(bookmark, opts);
    },
  };
}
