import { StreamDefinition } from '../realm/stream-definition.js';
import { StoredEvent } from '../event/stored-event.js';
import { RuntimeResult } from './runtime-result.js';
import { EventAuth } from '../event/event-auth.js';
import { EventContextStorage, EventHandleContext } from './runtime.js';
import { StreamHostRuntime } from './host-runtime.js';
import { EventRandomSeed } from '../event/event-random-seed.js';
import { getError } from './get-error.js';
import { hostResultBuilder } from './host-result-builder.js';
import { SnapshotClient } from '../storage/snapshot-client.js';
import {
  DeleteType,
  PatchType,
  StorageClient,
  UpsertType,
} from '../storage/storage-client.js';
import { RuntimeEnv } from '../runtime-env.js';
import {
  StateDefinitions,
  TypeOfStateDeclaration,
} from '../storage/state-declaration.js';
import { getStateData, getStateKeys } from './get-state-data-keys.js';
import { getOrFail } from '../realm/get-or-fail.js';

export function createStreamHost<
  TStream extends StreamDefinition<StateDefinitions, any, any, any, any>,
>(stream: TStream): StreamHostRuntime<TStream['state'], TStream['events']> {
  return {
    source: stream,
    runtime: {
      async execute(
        state: EventContextStorage<TStream['state']>,
        evt: StoredEvent,
        random: EventRandomSeed,
        env: RuntimeEnv,
      ): Promise<RuntimeResult> {
        try {
          const resultBuilder = hostResultBuilder(stream, evt);

          const ctx: EventHandleContext<any, any, any> = {
            env,
            stream: {
              pattern: stream.pattern,
              allowRead(authId: EventAuth, eventTypes: string[] | string) {
                resultBuilder.addPermissionChange(
                  authId,
                  'grant_event',
                  'read',
                  eventTypes,
                );
              },
              allowReadWrite(authId: EventAuth, eventTypes: string[] | string) {
                resultBuilder.addPermissionChange(
                  authId,
                  'grant_event',
                  'readwrite',
                  eventTypes,
                );
              },
              allowWrite(authId: EventAuth, eventTypes: string[] | string) {
                resultBuilder.addPermissionChange(
                  authId,
                  'grant_event',
                  'write',
                  eventTypes,
                );
              },
              revokeRead(authId: EventAuth, eventTypes: string[] | string) {
                resultBuilder.addPermissionChange(
                  authId,
                  'revoke_event',
                  'read',
                  eventTypes,
                );
              },
              revokeReadWrite(
                authId: EventAuth,
                eventTypes: string[] | string,
              ) {
                resultBuilder.addPermissionChange(
                  authId,
                  'revoke_event',
                  'readwrite',
                  eventTypes,
                );
              },
              revokeWrite(authId: EventAuth, eventTypes: string[] | string) {
                resultBuilder.addPermissionChange(
                  authId,
                  'revoke_event',
                  'write',
                  eventTypes,
                );
              },
            },
            async emit<K extends string>(type: K, data: any): Promise<void> {
              resultBuilder.addOutput(type, data);
            },
            state<K extends string>(
              name: K,
            ): StorageClient<TStream['state'][K]> {
              return {
                delete(id: DeleteType<TStream['state'][K]>): Promise<void> {
                  resultBuilder.addOperation({
                    type: 'delete',
                    state: name,
                    keys: getStateKeys(getOrFail(stream.state, name), id),
                  });
                  return state.state(name).delete(id);
                },
                patch(val: PatchType<TStream['state'][K]>): Promise<void> {
                  resultBuilder.addOperation({
                    type: 'patch',
                    state: name,
                    data: getStateData(
                      getOrFail(stream.state, name).type.description,
                      val,
                    ),
                    keys: getStateKeys(getOrFail(stream.state, name), val),
                  });
                  return state.state(name).patch(val);
                },
                insert(
                  val: TypeOfStateDeclaration<TStream['state'][K]>,
                ): Promise<void> {
                  resultBuilder.addOperation({
                    type: 'insert',
                    state: name,
                    data: getStateData(
                      getOrFail(stream.state, name).type.description,
                      val,
                    ),
                    keys: getStateKeys(getOrFail(stream.state, name), val),
                  });
                  return state.state(name).insert(val);
                },
                upsert(val: UpsertType<TStream['state'][K]>): Promise<void> {
                  resultBuilder.addOperation({
                    type: 'upsert',
                    state: name,
                    data: getStateData(
                      getOrFail(stream.state, name).type.description,
                      val,
                    ),
                    keys: getStateKeys(getOrFail(stream.state, name), val),
                  });
                  return state.state(name).upsert(val);
                },
              };
            },
            snapshot<K extends string & keyof TStream['state']>(
              name: K,
            ): SnapshotClient<TStream['state'][K]> {
              return state.snapshot(name);
            },
            random,
            metadata: {
              id: evt.id,
              auth: evt.auth,
              createdAt: evt.createdAt,
              storedAt: evt.storedAt,
              annotations: evt.annotations,
            },
          };

          await stream.handle[evt.event](evt.data, ctx);

          return resultBuilder.get();
        } catch (e) {
          return {
            success: false,
            error: getError(e),
          };
        }
      },
    },
  };
}
