import {
  ObserveIndexIteratorOptions,
  ObserveIndexWatchOptions,
  ObserveObject,
  Observer,
  ObserveWatcher,
} from './observer.js';
import { BrowseResult, RangeResult } from '../browse-result.js';
import { appliesFilter } from './applies-filter.js';
import { ObservedBatches, ObservedRange } from './observed-batches.js';
import { failNever } from '../../utils/fail-never.js';
import {
  StateDefinition,
  StateDefinitions,
  StateIndexKeys,
  TypeOfStateDeclaration,
} from '../state-declaration.js';
import { Lazy } from '../../management/lazy.js';
import { Queue } from '../../queue/queue.js';
import { createRunnable } from './create-runnable.js';
import {
  StateUpdateDelete,
  StateUpdateInsert,
  StateUpdatePatch,
  StateUpdateUpsert,
} from '../tracker/state-update.js';
import { getSortValue } from '../memory/map-resolve.js';
import { mapToState } from '../../runtime/get-state-data-keys.js';
import { getOrFail } from '../../realm/get-or-fail.js';
import { combineAbortSignal } from '../../utils/combine-abort-signal.js';
import { compareKeys } from './compare-keys.js';

function patchStateUpdate(
  item: ObservedBatches<StateDefinition<any, any, any>>,
  update: StateUpdatePatch | StateUpdateUpsert,
) {
  for (const i of item.result.items) {
    if (compareKeys(item.state.key, i.keys, update.keys)) {
      i.data = { ...(i.data as any), ...mapToState(item.state, update.data) }; // TODO merge deep
    }
  }
  // TODO re order potentially / remove load next
}

function deleteStateUpdate(
  item: ObservedBatches<StateDefinition<any, any, any>>,
  update: StateUpdateDelete,
) {
  for (const i of item.result.items) {
    if (compareKeys(item.state.key, i.keys, update.keys)) {
      item.result.items.splice(item.result.items.indexOf(i), 1);
      break;
    }
  }
  // TODO update bookmark
}

function isAfter<T>(
  item: {
    orderDirection: 'next' | 'prev';
    keys: StateIndexKeys<T>[];
  },
  v: ObserveObject<T>,
  update: StateUpdateInsert | StateUpdateUpsert,
) {
  for (const key of item.keys) {
    const dataSort = getSortValue(getOrFail(v.data as any, key));
    const updateSort = getSortValue(update.data[key + '.']!);
    if (item.orderDirection === 'next') {
      if (dataSort > updateSort) {
        return false;
      }
    } else {
      if (dataSort < updateSort) {
        return false;
      }
    }
  }

  return true;
}

function insertStateUpdate(
  item: ObservedBatches<any>,
  update: StateUpdateInsert | StateUpdateUpsert,
) {
  const index = item.result.items.findIndex((v) =>
    isAfter(item.watch, v, update),
  );

  if (
    item.watch.take > item.result.items.length ||
    index < item.result.items.length - 1
  ) {
    const newObj: ObserveObject<any> = {
      keys: update.keys,
      data: mapToState(item.state, update.data),
    };

    item.result.items.splice(index, 0, newObj);
    if (item.watch.take < item.result.items.length) {
      item.result.items.pop();
    }
    item.result.bookmark =
      item.result.items[item.result.items.length - 1]!.keys;
  }
}

export function createStateObserver<TState extends StateDefinitions>(
  storageName: string,
  state: TState,
  queue: Queue,
  signal: AbortSignal,
): Observer<TState> {
  return {
    watch<K extends keyof TState & string>(
      stateName: K,
    ): ObserveWatcher<TState[K]> {
      return {
        async *watchIterator(
          iterator: AsyncGenerator<
            RangeResult<TypeOfStateDeclaration<TState[K]>>
          >,
          opts: ObserveIndexIteratorOptions<TState[K]>,
        ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState[K]>>> {
          const currentItem: ObserveObject<TState[K]> | null = null;

          const item: ObservedRange<TState[K]> = {
            watch: opts,
            current: currentItem,
            stateName: stateName,
            state: state[stateName],
          };

          for await (const value of iterator) {
            item.current = value;
            yield value;
          }

          for await (const message of createRunnable({
            storageName,
            queue,
            state: stateName,
            signal: combineAbortSignal(signal, opts.signal),
          })) {
            if (item.stateName !== message.data.update.state) {
              continue;
            }

            if (
              message.data.update.type !== 'insert' &&
              message.data.update.type !== 'upsert'
            ) {
              continue;
            }

            if (
              item.current === null ||
              isAfter(item.watch, item.current, message.data.update)
            ) {
              item.current = {
                keys: message.data.update.keys,
                data: mapToState(item.state, message.data.update.data),
              };
              yield item.current;
            }
          }
        },
        async *watchBatch(
          resolver: Lazy<BrowseResult<TypeOfStateDeclaration<TState[K]>>>,
          opts: ObserveIndexWatchOptions<TState[K]>,
        ): AsyncGenerator<BrowseResult<TypeOfStateDeclaration<TState[K]>>> {
          const result = await resolver.resolve();

          const item: ObservedBatches<TState[K]> = {
            watch: opts,
            stateName,
            state: state[stateName],
            result,
          };

          yield result;

          for await (const message of createRunnable({
            storageName,
            queue,
            state: stateName,
            signal: combineAbortSignal(signal, opts.signal),
          })) {
            if (item.stateName !== message.data.update.state) {
              continue;
            }

            if (!appliesFilter(item, message.data.update)) {
              continue;
            }

            if (message.data.update.type === 'patch') {
              patchStateUpdate(item, message.data.update);
            } else if (message.data.update.type === 'insert') {
              insertStateUpdate(item, message.data.update);
            } else if (message.data.update.type === 'upsert') {
              patchStateUpdate(item, message.data.update);
              insertStateUpdate(item, message.data.update);
            } else if (message.data.update.type === 'delete') {
              deleteStateUpdate(item, message.data.update);
            } else {
              failNever(message.data.update, 'unknown state update');
            }

            yield item.result;
          }
        },
      };
    },
  };
}
