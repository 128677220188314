import {
  StateDefinition,
  StateIndex,
  TypeOfStateDeclaration,
} from '../storage/state-declaration.js';
import { Lazy } from './lazy.js';
import {
  FilterKey,
  FilterStateIndex,
  FilterValue,
  RangeOptions,
  SnapshotIndexClient,
} from '../storage/snapshot-index-client.js';
import { RangeResult } from '../storage/browse-result.js';
import { lazyWrap } from './lazy-wrap.js';

export function createSnapshotIndex<
  TState extends StateDefinition<any, any, any>,
  TIndex extends StateIndex<any, any>,
>(
  indexDefinition: TIndex,
  keyIndex: number,
  lazyStorage: Lazy<SnapshotIndexClient<TState, TIndex>>,
): SnapshotIndexClient<TState, TIndex> {
  return {
    async *range(
      bookmark: FilterValue<TState, TIndex> | null,
      opts?: Partial<RangeOptions>,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState>>> {
      const value = await lazyStorage.resolve();
      yield* value.range(bookmark, opts);
    },
    filter(
      key: FilterKey<TIndex>,
      value: FilterValue<TState, TIndex>,
    ): SnapshotIndexClient<TState, FilterStateIndex<TIndex>> {
      return createSnapshotIndex(
        indexDefinition,
        keyIndex + 1,
        lazyWrap(lazyStorage, (storage) => storage.filter(key, value)),
      );
    },
  };
}
