import { StreamEventDeclaration } from '../realm/stream-event-declaration.js';
import { EventDeclarations } from '../event/event-declarations.js';
import { Type } from '../typing/type.js';
import { TypeDescription } from '../typing/type-description.js';
import {
  getJsonSchemaDescription,
  getTypeFromJsonSchemaDescription,
} from '../rpc/server/openapi/json-schema.js';

export function mapEventDeclarations(
  events: Record<string, { description: TypeDescription }>,
): {
  [key: string]: StreamEventDeclaration;
} {
  return Object.entries(events).reduce<Record<string, StreamEventDeclaration>>(
    (map, [key, value]) => {
      map[key] = {
        schema: getJsonSchemaDescription(value.description),
      };
      return map;
    },
    {},
  );
}

export function mapEventDefinition(
  state: Record<string, StreamEventDeclaration>,
): EventDeclarations {
  return Object.entries(state).reduce<Record<string, Type<any, any>>>(
    (map, [key, value]) => {
      map[key] = getTypeFromJsonSchemaDescription(value.schema);
      return map;
    },
    {},
  );
}
