import { createType } from './create-type.js';
import { DateTypeDescription } from './date-type-description.js';
import { ValidateError } from './validate-error.js';

export function getDate(val: unknown): Date | null {
  if (val instanceof Date) {
    return val;
  } else if (Object.prototype.toString.call(val)) {
    const date = new Date(val as Date);
    if (!isNaN(date.getTime())) {
      return date;
    }
  } else if (typeof val === 'string') {
    const date = new Date(val);
    if (!isNaN(date.getTime())) {
      return date;
    }
  }

  return null;
}

export const date = () =>
  createType<Date, DateTypeDescription>({
    description: { type: 'date' },
    validate: (val, path) => {
      const date = getDate(val);
      if (date) {
        return { success: true, data: date };
      } else {
        return {
          success: false,
          error: new ValidateError(
            'not a valid date at ' + path.join('->'),
            path,
            val,
          ),
        };
      }
    },
  });
