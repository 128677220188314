import {
  StateDefinition,
  StateIndex,
  TypeOfStateDeclaration,
} from '@aion/core/storage/state-declaration.js';
import { Lazy } from '@aion/core/management/lazy.js';
import { IDBPDatabase } from 'idb';
import {
  FilterKey,
  FilterStateIndex,
  FilterValue,
  RangeOptions,
  SnapshotIndexClient,
} from '@aion/core/storage/snapshot-index-client.js';
import { CustomError } from '@aion/core/custom-error.js';
import { RangeResult } from '@aion/core/storage/browse-result.js';
import { parseData } from './parse-data.js';
import { getStateKeys } from '@aion/core/runtime/get-state-data-keys.js';

export function createSnapshotIndexClient<
  TState extends StateDefinition<any, any, any>,
  TIndex extends StateIndex<any, string[]>,
>(
  dbPromise: Lazy<IDBPDatabase>,
  storeName: string,
  type: StateDefinition<TState, any, any>,
  index: string,
  indexType: TIndex,
  filter: { key: string; value: any }[],
): SnapshotIndexClient<TState, TIndex> {
  function buildKey(
    bookmark: FilterValue<TState, TIndex> | null,
    opts: Required<RangeOptions>,
  ): IDBKeyRange | null {
    if (!bookmark) {
      return null;
    }
    if (opts.dir === 'next') {
      return IDBKeyRange.lowerBound([bookmark], opts.open);
    } else {
      return IDBKeyRange.upperBound([bookmark], opts.open);
    }
  }

  function buildFilterKey(
    bookmark: FilterValue<TState, TIndex> | null,
    opts: Required<RangeOptions>,
  ): IDBKeyRange | null {
    return IDBKeyRange.bound(
      indexType.fields.map((f, index) => {
        const filterItem = filter[index];
        if (filterItem) {
          return filterItem.value;
        } else if (opts.dir === 'next' && !!bookmark) {
          return bookmark;
        } else {
          const fieldType = type.type.description.object[f];
          if (!fieldType) {
            throw new CustomError('unknown field', null, {
              field: f,
            });
          }
          if (fieldType.type === 'date') {
            return new Date(-8640000000000000);
          } else if (fieldType.type === 'number') {
            return -Infinity;
          } else if (fieldType.type === 'string') {
            return '';
          } else {
            throw new CustomError('unsupported type', null, {
              type: fieldType,
            });
          }
        }
      }),
      indexType.fields.map((f, index) => {
        const filterItem = filter[index];
        if (filterItem) {
          return filterItem.value;
        } else if (opts.dir === 'prev' && !!bookmark) {
          return bookmark;
        } else {
          const fieldType = type.type.description.object[f];
          if (!fieldType) {
            throw new CustomError('unknown field', null, {
              field: f,
            });
          }

          if (fieldType.type === 'date') {
            return new Date(8640000000000000);
          } else if (fieldType.type === 'number') {
            return Infinity;
          } else if (fieldType.type === 'string') {
            return '\uffff';
          } else {
            throw new CustomError('unsupported type', null, {
              type: fieldType,
            });
          }
        }
      }),
      opts.dir === 'next' ? opts.open : false,
      opts.dir === 'prev' ? opts.open : false,
    );
  }

  return {
    async *range(
      prefix: FilterValue<TState, TIndex> | null,
      opts?: RangeOptions,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState>>> {
      let open = opts?.open ?? false;
      const direction = opts?.dir ?? 'next';
      let currentBookmark = prefix;
      do {
        const db = await dbPromise.resolve();
        const trx = db.transaction(storeName, 'readonly');
        const idx = trx.store.index(index);
        const range =
          Object.keys(filter).length > 0
            ? buildFilterKey(currentBookmark, {
                open: open,
                dir: direction,
              })
            : buildKey(currentBookmark, {
                open: open,
                dir: direction,
              });

        const batch: RangeResult<TypeOfStateDeclaration<TState>>[] = [];
        let cursor = await idx.openCursor(range, direction);
        while (cursor) {
          if (!cursor.value) {
            for (const item of batch) {
              yield item;
            }
            return;
          }
          const data = parseData(cursor.value, type);
          batch.push({
            keys: getStateKeys(type, data),
            data,
          });
          cursor = await cursor.continue();
        }

        for (const item of batch) {
          yield item;
          currentBookmark = item.data;
          open = true;
        }
        if (!cursor) {
          return;
        }
      } while (true);
    },
    filter(
      key: FilterKey<TIndex> & string,
      value: FilterValue<TState, TIndex>,
    ): SnapshotIndexClient<TState, FilterStateIndex<TIndex>> {
      return createSnapshotIndexClient(
        dbPromise,
        storeName,
        type,
        index,
        indexType,
        [...filter, { key, value }],
      );
    },
  };
}
