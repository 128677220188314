import { StateDeclarations } from '@aion/core/storage/state-declaration.js';
import { EventDeclarations } from '@aion/core/event/event-declarations.js';
import { StreamInterface } from '@aion/core/management/stream-interface.js';
import { StoredEvent } from '@aion/core/event/stored-event.js';
import { StreamPushOptions } from '@aion/core/management/stream-push-options.js';
import { ObservedClient } from '@aion/core/storage/observer/observed-client.js';
import { createRemoteObservedClient } from './create-remote-observed-client.js';
import { SnapshotClient } from '@aion/core/storage/snapshot-client.js';
import { createRemoteSnapshotClient } from './create-remote-snapshot-client.js';
import { RemoteDescription } from './remote-description.js';
import { RpcClient } from '../api/create-rpc-client.js';
import { SessionManager } from '../auth/jwt-session.js';
import { Lazy } from '@aion/core/management/lazy.js';
import { createFakeSignal } from '@aion/core/utils/fake-signal.js';

export function createRemoteStreamInterface<
  TState extends StateDeclarations,
  TEvent extends EventDeclarations,
>(
  state: TState,
  rpc: RpcClient,
  session: SessionManager,
  lazyVersion: Lazy<string>,
  streamIdentifier: RemoteDescription,
): StreamInterface<TState, TEvent> {
  return {
    state,
    async get(id: string): Promise<StoredEvent | null> {
      const response = await rpc['event.get'].call(
        {
          realm: streamIdentifier.realm,
          tenant: streamIdentifier.tenant,
          version: await lazyVersion.resolve(),
          pattern: streamIdentifier.pattern,
          id: id,
          args: streamIdentifier.args,
          token: await session.resolveToken(),
        },
        createFakeSignal(),
      );
      return response.result;
    },
    async push<K extends string & keyof TEvent>(
      evt: K,
      data: TEvent[K]['_output'],
      options?: Partial<StreamPushOptions>,
    ): Promise<StoredEvent> {
      const response = await rpc['event.push'].call(
        {
          realm: streamIdentifier.realm,
          tenant: streamIdentifier.tenant,
          version: await lazyVersion.resolve(),
          pattern: streamIdentifier.pattern,
          event: evt,
          data,
          annotations: options?.annotations,
          createdAt: options?.createdAt,
          id: options?.id,
          args: streamIdentifier.args,
          token: await session.resolveToken(),
        },
        createFakeSignal(),
      );
      return response.result;
    },
    observe<K extends string & keyof TState>(
      name: K,
    ): ObservedClient<TState[K]> {
      return createRemoteObservedClient(
        state[name]!,
        rpc,
        session,
        lazyVersion,
        'stream',
        streamIdentifier,
        name,
      );
    },
    snapshot<K extends string & keyof TState>(
      name: K,
    ): SnapshotClient<TState[K]> {
      return createRemoteSnapshotClient(
        state[name]!,
        rpc,
        session,
        lazyVersion,
        'stream',
        streamIdentifier,
        name,
      );
    },
  };
}
