export function combineAbortSignal(first: AbortSignal, second: AbortSignal) {
  const ctrl = new AbortController();

  first.addEventListener(
    'abort',
    () => {
      ctrl.abort();
    },
    { once: true },
  );

  second.addEventListener(
    'abort',
    () => {
      ctrl.abort();
    },
    { once: true },
  );

  return ctrl.signal;
}
