import { StateDeclarationIndex, StreamState } from '../realm/stream-state.js';
import { TypeDescription } from '../typing/type-description.js';
import { StateDefinitions } from '../storage/state-declaration.js';
import {
  getJsonSchemaDescription,
  getTypeFromJsonSchemaDescription,
} from '../rpc/server/openapi/json-schema.js';

export function mapStateDeclarations(states: {
  [key: string]: {
    type: { description: TypeDescription };
    indices: StateDeclarationIndex;
    key: string[];
  };
}): {
  [key: string]: StreamState;
} {
  return Object.entries(states).reduce<Record<string, StreamState>>(
    (map, [key, value]) => {
      map[key] = {
        indices: value.indices,
        schema: getJsonSchemaDescription(value.type.description),
        key: value.key,
      };
      return map;
    },
    {},
  );
}

export function mapStateDefinition(
  state: Record<string, StreamState>,
): StateDefinitions {
  return Object.entries(state).reduce<StateDefinitions>((map, [key, value]) => {
    map[key] = {
      key: value.key,
      indices: value.indices,
      type: getTypeFromJsonSchemaDescription(value.schema),
    };
    return map;
  }, {});
}
