import {
  StateDefinition,
  TypeOfStateDeclaration,
} from '../storage/state-declaration.js';
import { PatchType, UpsertType } from '../storage/storage-client.js';
import { isStateValue, StateValue } from '../storage/state-value.js';
import { CustomError } from '../custom-error.js';
import { TypeDescription } from '../typing/type-description.js';
import { ObjectTypeDescription } from '../typing/object-type-description.js';
import { failNever } from '../utils/fail-never.js';
import { isArray } from '../typing/array.js';
import { getDate } from '../typing/date.js';

export function getStateDataKeys<TState extends StateDefinition<any, any, any>>(
  state: TState,
  val: UpsertType<TState> | PatchType<TState> | TypeOfStateDeclaration<TState>,
): {
  data: Record<string, StateValue>;
  keys: Record<string, StateValue>;
} {
  return {
    data: getStateData(state.type.description, val),
    keys: getStateKeys(state, val),
  };
}

export function getStateData<TState extends StateDefinition<any, any, any>>(
  description: ObjectTypeDescription,
  val: UpsertType<TState> | PatchType<TState> | TypeOfStateDeclaration<TState>,
): Record<string, StateValue> {
  return iterateData(description, '', val);
}

function iterateData(
  type: TypeDescription,
  prefix: string,
  val: unknown,
): Record<string, StateValue> {
  if (type.type === 'string') {
    if (typeof val === 'string') {
      return { [prefix]: val };
    } else {
      throw new CustomError('unable to map string', null, {
        key: prefix,
      });
    }
  } else if (type.type === 'number') {
    if (typeof val === 'number') {
      return { [prefix]: val };
    } else {
      throw new CustomError('unable to map number', null, {
        key: prefix,
      });
    }
  } else if (type.type === 'boolean') {
    if (typeof val === 'boolean') {
      return { [prefix]: val };
    } else {
      throw new CustomError('unable to map boolean', null, {
        key: prefix,
      });
    }
  } else if (type.type === 'date') {
    const date = getDate(val);
    if (date) {
      return { [prefix]: date };
    } else {
      throw new CustomError('unable to map date', null, {
        key: prefix,
      });
    }
  } else if (type.type === 'numberAsText') {
    if (typeof val === 'string' && !isNaN(parseFloat(val))) {
      return { [prefix]: val };
    } else {
      throw new CustomError('unable to map numberAsText', null, {
        key: prefix,
      });
    }
  } else if (type.type === 'literal') {
    if (
      val === type.constant &&
      (typeof val === 'string' ||
        typeof val === 'number' ||
        typeof val === 'boolean')
    ) {
      return { [prefix]: val };
    } else {
      throw new CustomError('unable to map literal', null, {
        key: prefix,
      });
    }
  } else if (type.type === 'array') {
    if (isArray(val)) {
      const items = Object.values(val);
      let data: Record<string, StateValue> = {};
      for (let i = 0; i < items.length; i++) {
        const itemValue = iterateData(
          type.itemType,
          `${prefix}.${i}`,
          items[i],
        );
        if (itemValue) {
          data = { ...data, ...itemValue };
        } else {
          throw new CustomError('unable to map array item', null, {
            key: prefix,
            index: i,
          });
        }
      }
      return data;
    } else {
      throw new CustomError('unable to map array', null, {
        key: prefix,
      });
    }
  } else if (type.type === 'object') {
    if (val === null || val === undefined || typeof val !== 'object') {
      throw new CustomError('unable to map empty object', null, {
        key: prefix,
      });
    }

    let data: Record<string, StateValue> = {};
    for (const [key, value] of Object.entries(val)) {
      const propType = type.object[key];
      if (propType) {
        const propData = iterateData(
          propType,
          prefix.length > 0 ? `${prefix}.${key}` : key,
          value as any,
        );
        if (!propData) {
          throw new CustomError('unable to map object property', null, {
            key: prefix,
            propertyName: key,
          });
        }
        data = { ...data, ...propData };
      }
    }
    return data;
  } else if (type.type === 'optional') {
    try {
      return iterateData(type.optionalType, prefix, val);
    } catch (e) {
      if (e instanceof CustomError) {
        return {};
      } else {
        throw e;
      }
    }
  } else if (type.type === 'nullable') {
    try {
      return iterateData(type.nullableType, prefix, val);
    } catch (e) {
      if (e instanceof CustomError) {
        return {};
      } else {
        throw e;
      }
    }
  } else if (type.type === 'union') {
    for (const unionType of type.unionTypes) {
      try {
        return iterateData(unionType, prefix, val);
      } catch (e) {
        if (!(e instanceof CustomError)) {
          throw e;
        }
      }
    }
    throw new CustomError('unable to map union', null, {
      key: prefix,
    });
  } else if (type.type === 'record') {
    if (val === null || val === undefined || typeof val !== 'object') {
      throw new CustomError('unable to map empty record', null, {
        key: prefix,
      });
    }

    let data: Record<string, StateValue> = {};
    for (const [key, value] of Object.entries(val)) {
      const recordData = iterateData(
        type.valueType,
        prefix.length > 0 ? `${prefix}.${key}` : key,
        value,
      );
      data = { ...data, ...recordData };
    }

    return data;
  } else if (type.type === 'func' || type.type === 'promise') {
    throw new CustomError('unparsable type', null, {
      type: type.type,
    });
  } else {
    failNever(type, 'unexpected type');
  }
}

export function getStateKeys<TState extends StateDefinition<any, any, any>>(
  state: TState,
  val: UpsertType<TState> | PatchType<TState> | TypeOfStateDeclaration<TState>,
): Record<string, StateValue> {
  const keys: Record<string, StateValue> = {};

  for (const key of state.key) {
    const value = key in val ? (val as any)[key] : null;
    if (isStateValue(value)) {
      keys[key] = value;
    } else {
      throw new CustomError('invalid key value', null, { keys });
    }
  }

  return keys;
}

export function mapToState<TState extends StateDefinition<any, any, any>>(
  state: TState,
  data: Record<string, StateValue>,
): TypeOfStateDeclaration<TState> {
  return iterateMap(state.type.description, '', { ...data });
}

function getValuesWithPrefix(
  prefix: string,
  value: Record<string, StateValue>,
): Record<string, StateValue> {
  return Object.entries(value).reduce<Record<string, StateValue>>(
    (map, [key, value]) => {
      if (key.startsWith(prefix)) {
        map[key] = value;
      }
      return map;
    },
    {},
  );
}

function iterateMap(
  type: TypeDescription,
  prefix: string,
  value: Record<string, StateValue>,
): any {
  if (
    type.type === 'string' ||
    type.type === 'number' ||
    type.type === 'boolean' ||
    type.type === 'date' ||
    type.type === 'numberAsText' ||
    type.type === 'literal'
  ) {
    return value[prefix];
  } else if (type.type === 'array') {
    const result: any[] = [];
    let i = 0;
    let indexValue: Record<string, StateValue> = {};

    do {
      indexValue = getValuesWithPrefix(getPrefixedKey(prefix, `${i}`), value);
      if (Object.keys(indexValue).length > 0) {
        result.push(
          iterateMap(type.itemType, getPrefixedKey(prefix, `${i}`), indexValue),
        );
      } else {
        break;
      }
      i++;
    } while (Object.keys(indexValue).length > 0);
    return result;
  } else if (type.type === 'object') {
    const result: any = {};

    for (const [key, propType] of Object.entries(type.object)) {
      const propValues = getValuesWithPrefix(
        getPrefixedKey(prefix, key),
        value,
      );
      if (Object.keys(propValues).length === 0) {
        continue;
      }
      result[key] = iterateMap(
        propType,
        getPrefixedKey(prefix, key),
        propValues,
      );
    }

    return result;
  } else if (type.type === 'nullable') {
    if (Object.keys(value).length === 0) {
      return null;
    } else {
      return iterateMap(type.nullableType, prefix, value);
    }
  } else if (type.type === 'optional') {
    if (Object.keys(value).length === 0) {
      return undefined;
    } else {
      return iterateMap(type.optionalType, prefix, value);
    }
  } else if (type.type === 'union') {
    for (const unionType of type.unionTypes) {
      const unionValue = iterateMap(unionType, prefix, value);
      if (unionValue) {
        return unionValue;
      }
    }
    throw new CustomError('unparsable union', null, {
      unionName: type.name,
    });
  } else if (type.type === 'record') {
    const result: any = {};

    const recordValues = getValuesWithPrefix(getPrefixedKey(prefix, ''), value);
    for (const [key, recordValue] of Object.entries(recordValues)) {
      const nextDotIndex = key.indexOf('.', prefix.length + 1);
      if (nextDotIndex === -1) {
        result[key.substring(prefix.length + 1)] = iterateMap(
          type.valueType,
          key,
          { [key]: recordValue },
        );
      } else {
        const recordKey = key.substring(prefix.length + 1, nextDotIndex);
        const subRecordValues = getValuesWithPrefix(
          getPrefixedKey(prefix, recordKey),
          value,
        );
        result[recordKey] = iterateMap(
          type.valueType,
          getPrefixedKey(prefix, recordKey),
          subRecordValues,
        );
      }
    }
    return result;
  } else if (type.type === 'func' || type.type === 'promise') {
    throw new CustomError('unparsable type', null, {
      type: type.type,
    });
  } else {
    failNever(type, 'unexpected type');
  }
}

function getPrefixedKey(prefix: string, key: string) {
  if (prefix.length === 0) {
    return key;
  } else {
    return `${prefix}.${key}`;
  }
}
